// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import { cilAt } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CAlert,
  CButton,
  CCardText,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { get, post, postUnwrapped } from 'src/services/http-service'
import {
  ENDPOINT_GET_EXPORT_USERS_CSV,
  ENDPOINT_GET_REDEEM_CODES,
  ENDPOINT_GET_USERS,
  ENDPOINT_POST_BURN_TOKENS,
  ENDPOINT_POST_SEND_REFERRAL,
  ENDPOINT_POST_SYNC_BALANCES,
} from 'src/variables'
import { object, string, number } from 'yup'

const Users = () => {
  const userToken = useSelector((state) => state.user.token)
  const [users, setUsers] = useState([])
  const [userDetailsOpen, setUserDeatailsOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [syncRequestState, setSyncRequestState] = useState('not_sent')
  const [exportRequestState, setExportRequestState] = useState('not_sent')
  const [syncAlertOpen, setSyncAlertOpen] = useState(false)
  const [exportAlertOpen, setExportAlertOpen] = useState(false)
  const [sendBurnRequestStatus, setSendBurnRequestStatus] = useState('not_sent')

  const resolverSchema = object({
    amount: number()
      .required(`La quantitá di token da bruciare é obbligatoria`)
      .min(1, 'La quantitá deve essere maggiore o uguale a 1'),
  }).required()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resolverSchema) })

  async function fetchUsers() {
    const res = await get({
      endpoint: ENDPOINT_GET_USERS,
      bearerToken: userToken,
    })

    setUsers(res)
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  useEffect(() => {
    if (userDetailsOpen) {
      setSendBurnRequestStatus('not_sent')
    }
  }, [userDetailsOpen])

  const submit = async (e) => {
    setSendBurnRequestStatus('pending')
    try {
      const res = await post({
        endpoint: ENDPOINT_POST_BURN_TOKENS,
        bearerToken: userToken,
        data: {
          userId: selectedUser.id,
          amount: e.amount,
        },
      })
      await fetchUsers()
      setSendBurnRequestStatus('success')
    } catch (err) {
      setSendBurnRequestStatus('failure')
    }
  }
  const syncBalances = async () => {
    try {
      setSyncRequestState('pending')
      setSyncAlertOpen(true)
      setTimeout(() => {
        setSyncAlertOpen(false)
      }, 5000)
      const res = await postUnwrapped({
        endpoint: ENDPOINT_POST_SYNC_BALANCES,
        bearerToken: userToken,
      })
      setSyncRequestState('success')
      if (res.status === 202) {
        alert('Sincronizzazione in corso, potrebbero volerci alcuni minuti.')
      } else {
        alert('Sincronizzazione completata')
        window.location.reload()
      }
    } catch (err) {
      setSyncRequestState('failure')
    }
  }

  const exportCVS = async () => {
    try {
      setExportRequestState('pending')
      // setExportAlertOpen(true)
      // setTimeout(() => {
      //   setExportAlertOpen(false)
      // }, 5000)
      const res = await get({
        endpoint: ENDPOINT_GET_EXPORT_USERS_CSV,
        bearerToken: userToken,
      })

      const generatedBlob = new Blob([res], { type: 'text/plain;charset=utf-8' })
      FileSaver.saveAs(generatedBlob, 'exported-users.csv')
      setExportRequestState('success')
    } catch (err) {
      setExportRequestState('failure')
      alert('Esportazione fallita:', err?.message ?? err)
    }
  }

  return (
    <>
      <CButton
        className="w-100 mb-3"
        disabled={exportRequestState === 'pending'}
        onClick={() => exportCVS()}
      >
        Esporta CSV
      </CButton>
      <CButton
        className="w-100 mb-3"
        disabled={syncRequestState === 'pending'}
        onClick={() => syncBalances()}
      >
        Sincronizza bilanci
      </CButton>
      <CTable striped hover bordered color="light">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Username</CTableHeaderCell>
            <CTableHeaderCell scope="col">Email</CTableHeaderCell>
            <CTableHeaderCell scope="col">Ruolo</CTableHeaderCell>
            <CTableHeaderCell scope="col">Bilancio</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {users.map((el, index) => (
            <CTableRow
              key={index}
              onClick={() => {
                setSelectedUser(el)
                setUserDeatailsOpen(true)
              }}
            >
              <CTableHeaderCell scope="row">{el.username}</CTableHeaderCell>
              <CTableDataCell>{el.email}</CTableDataCell>
              <CTableDataCell>{el.role}</CTableDataCell>
              <CTableDataCell>{el.balance} ZNZ</CTableDataCell>
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>
      {selectedUser && (
        <CModal
          keyboard={false}
          visible={userDetailsOpen}
          onClose={() => setUserDeatailsOpen(false)}
          alignment="center"
        >
          <CModalHeader>
            <CModalTitle>{selectedUser.username}</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CContainer>
              <CRow>
                <CCol sm={3}>Email:</CCol>
                <CCol sm={9}>{selectedUser.email}</CCol>
              </CRow>
              <CRow>
                <CCol sm={3}>Bilancio:</CCol>
                <CCol sm={9}>{selectedUser.balance} ZNZ</CCol>
              </CRow>
              {/* <CRow className="justify-content-center">
                <CCol sm="auto">
                  <QRCode level="M" value={selectedUser.value} />
                </CCol>
              </CRow> */}
              <CRow className="mt-3">
                <CCol sm={12}>
                  <h3>Brucia token</h3>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={12}>
                  <CForm onSubmit={handleSubmit(submit)}>
                    <CInputGroup>
                      <Controller
                        render={({ field }) => {
                          return (
                            <>
                              <CFormInput
                                placeholder="Quantitá"
                                type="number"
                                autoComplete="quantitá"
                                onChange={field.onChange}
                              />
                              <CCol xs={12}>
                                <span>{errors[field.name]?.message}</span>
                              </CCol>
                            </>
                          )
                        }}
                        name="amount"
                        control={control}
                      />
                    </CInputGroup>
                    {sendBurnRequestStatus === 'not_sent' && (
                      <CButton type="submit" className="w-50 mt-3 mx-auto d-block">
                        Invia
                      </CButton>
                    )}
                    {sendBurnRequestStatus === 'pending' && (
                      <div className="w-100 mt-3 d-flex flex-column justify-content-center align-items-center">
                        <CCardText>
                          Per favore, attendi mentre la richiesta viene processata...
                        </CCardText>
                        <CSpinner />
                      </div>
                    )}
                    {sendBurnRequestStatus === 'success' && (
                      <p className="w-100 mt-3 text-center">Token bruciati con successo</p>
                    )}
                    {sendBurnRequestStatus === 'failure' && (
                      <p className="w-100 mt-3 text-center">{`Errore durante la cancellazione dei token, chiudi il pannello e riprova`}</p>
                    )}
                  </CForm>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={12}></CCol>
              </CRow>
            </CContainer>
          </CModalBody>
        </CModal>
      )}
      <CAlert className="sync-alert w-75" color="info" visible={syncAlertOpen}>
        Sincronizzazione avviata, il processo potrebbe richiedere alcuni minuti
      </CAlert>
      {/* <CAlert className="sync-alert w-75" color="info" visible={exportAlertOpen}>
        Esportazione avviata, il processo potrebbe richiedere alcuni minuti
      </CAlert> */}
    </>
  )
}

export default Users
